import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);
const store = new Vuex.Store({
    // 开启严格模式，任何对状态的更改如果不是通过mutation操作引起的，将会报警告
    // strict: true,
    state: {
        token: localStorage.getItem("token"),
    },

    // 获取
    getters: {
        getToken(state) {
            return state.token;
        },
    },

    // 修改
    mutations: {

    },
    // 配置持久化，默认持久化所有state
    plugins: [createPersistedState()],
});

export default store;
