import axios from "@/http";

// 短信验证码
function smsCheckCode(data) {
  return axios({
      method: "post",
      data,
  });
}

// face登录
function faceLogin(data) {
  return axios({
      method: "post",
      data,
  });
}


// 获取图片验证码
function imageCheckCode(data) {
  return axios({
      method: "post",
      data,
  });
}

function userLogin(data) {
  return axios({
      method: "post",
      data,
  });
}

export { smsCheckCode, faceLogin, imageCheckCode, userLogin };
