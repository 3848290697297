import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Login from "@/views/login.vue";
import store from "@/store/index";
import { Message } from "element-ui";

Vue.use(VueRouter);

let originPush = VueRouter.prototype.push; //备份原push方法

// 重写 Vue-router 原型对象上的 push 函数
// 防止多次点击跳转同一个路由时报错
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    } else {
        //如果没有传回调函数，手动添加
        originPush.call(
            this,
            location,
            () => {
            },
            () => {
            }
        );
    }
};

const routes = [
    // 登录页面
    {
        path: "/login/:id",
        component: Login,
    },
    // 后台首页
    {
        path: "/home/:id",
        component: () => import("@/views/home.vue")
    },
    {
        path: "/404",
        component: () => import("@/views/rests/404.vue"),
        meta: {
            title: "404",
        },
    },
    {
        path: "*",
        redirect: "/404",
        hidden: true,
    },
];

const router = new VueRouter({
    routes,
});

//  登录拦截
// router.beforeEach(function (to, form, next) {
//     if (to.path !== "/") {
//         if (store.state.token) {
//             // 有token放行
//             next();
//         } else {
//             // 没有跳转到登录界面
//             Message({
//                 message: "请先进行登录",
//                 type: "warning",
//             });
//             next("/");
//         }
//     } else {
//         next();
//     }
// });

export { router as default };
