<template>
    <div>
        <el-dialog title="请完成下列验证后继续：" :visible.sync="visible" width="390px" :before-close="handleClose">
            <div style="text-align: center;">
                <slide-verify :l="42"
                :r="10"
                :w="350"
                :h="155"
                :imgs="imgs"
                slider-text="向右滑动"
                @success="onSuccess"
                @fail="onFail"
                @refresh="onRefresh"
                @fulfilled="onFulfilled"
                @again="onAgain"
            ></slide-verify>
            </div>
            <div>{{msg}}</div>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                visible: true,
                msg: '',
                imgs: [
                    require('@/assets/11.png'),
                    require('@/assets/22.png'),
                    require('@/assets/33.png'),
                    require('@/assets/44.png'),
                    require('@/assets/55.png'),
                    require('@/assets/66.png'),
                    require('@/assets/77.png'),
                    require('@/assets/88.png'),
                   
                ]
            }
        },
        methods: {
            onSuccess(){
                this.msg = 'login success'
                this.visible = false
                this.$emit('back',true)
            },
            onFail(){
                this.msg = ''
            },
            onRefresh(){
                this.msg = ''
            },
            // 刷新成功
            onFulfilled () {
            },
            // 非人为操作
            onAgain () {
                console.log("非人为操作")
            },
            // 关闭
            handleClose () {
                this.visible = false
                this.$emit('back',false)
            }
        }
    }
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
    padding-top: 5px;
}
</style>
