import axios from "@/http";

// 获取商品一/二级分类
function getGoodsCategory(data) {
    return axios({
        method: "post",
        data: {
            user_id: localStorage.getItem("user_id"),
            ...data
        },
    });
}

// 获取商品详情
function getGoodsDetail(data) {
    return axios({
        method: "post",
        data: {
            user_id: localStorage.getItem("user_id"),
            ...data
        },
    });
}

// 兑换商品
function exchangeGoods(data) {
    return axios({
        method: "post",
        data: {
            user_id: localStorage.getItem("user_id"),
            ...data
        },
    });
}

// 获取用户信息
function getUser(data) {
    return axios({
        method: "post",
        data: {
            user_id: localStorage.getItem("user_id"),
            ...data
        },
    });
}

// 扫脸登录
function scanFaceLogin(data) {
    return axios({
        method: "post",
        data,
    });
}

// 退出登录
function logOut(data) {
    return axios({
        method: "post",
        data,
    });
}

export { getGoodsCategory, getGoodsDetail, exchangeGoods, getUser, scanFaceLogin, logOut };
