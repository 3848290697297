<template>
  <div class="main login-container">
    <div class="left">
      <img src="@/assets/login/text.png" />
      <!-- <span>积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明
        积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明积分商城兑换规则说明
      </span> -->
    </div>
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      auto-complete="on"
      label-position="left"
    >
      <div class="login-box-wrap">
        <div class="login-box-bg">
          <div class="login-box-main">
            <div class="login-navs">
              <div
                class="login-navs-item"
                :class="{ on: currentIndex === 1 }"
                @click="handleTabChange(1)"
              >
                账号登录
              </div>
              <div class="line"></div>
              <div
                class="login-navs-item"
                :class="{ on: currentIndex === 2 }"
                @click="handleTabChange(2)"
              >
                短信登录
              </div>
              <div class="line"></div>
              <div
                class="login-navs-item"
                :class="{ on: currentIndex === 3 }"
                @click="handleTabChange(3)"
              >
                人脸识别
              </div>
            </div>
            <div class="login-form-item" v-if="currentIndex === 1">
              <el-form-item prop="account">
                <el-input
                  ref="username"
                  v-model="loginForm.account"
                  placeholder="请输入账号"
                  name="loginName"
                  type="text"
                  tabindex="1"
                  auto-complete="on"
                />
              </el-form-item>

              <el-form-item prop="pwd">
                <el-input
                  :key="passwordType"
                  ref="password"
                  v-model="loginForm.pwd"
                  :type="passwordType"
                  placeholder="请输入密码"
                  name="passWord"
                  tabindex="2"
                  auto-complete="on"
                  @keyup.enter.native="handleLogin"
                />
              </el-form-item>
              <el-form-item label="" prop="img_code" class="code-box">
                <el-input
                  ref="username"
                  v-model="loginForm.img_code"
                  placeholder="请输入图片验证码"
                  name="img_code"
                  auto-complete="on"
                />
                <img :src="imgUrl" title="图片验证码" @click="getImgCode" />
              </el-form-item>
            </div>
            <div class="login-form-item" v-if="currentIndex === 2">
              <el-form-item label="" prop="account">
                <el-input
                  ref="username"
                  v-model="loginForm.account"
                  placeholder="请输入手机号"
                  name="loginName"
                  type="number"
                  auto-complete="on"
                  oninput="if(value.length>11)value=value.slice(0,11)"
                />
              </el-form-item>

              <el-form-item label="" prop="sms_code" class="code-box">
                <el-input
                  :key="passwordType"
                  ref="securityCode"
                  v-model="loginForm.sms_code"
                  placeholder="请输入验证码"
                  name="securityCode"
                  tabindex="2"
                  auto-complete="on"
                  @keyup.enter.native="handleLogin"
                />
                <span v-if="isCountDown" class="code-down"
                  >{{ count_down }}s</span
                >
                <span @click="handleSendCode" v-else class="code-text"
                  >发送验证码</span
                >
              </el-form-item>
            </div>
            <div class="login-form-item" v-if="currentIndex === 3">
              <img class="renlianimg" v-show="!isShowV" src="@/assets/login/renlian.png" alt="" />
              <div class="big-box" v-show="isShowV">
                <div class="video-box">
                  <video
                    id="video"
                    width="320"
                    height="240"
                    preload
                    autoplay
                    loop
                    muted
                  ></video>
                  <canvas id="canvas" width="320" height="240"></canvas>
                </div>
                <div class="success-face">
                  <canvas
                    id="screenshotCanvas"
                    width="320"
                    height="240"
                    style="display: none"
                  ></canvas>
                  <div class="switch-button">
                    <el-row>
                      <el-button type="primary" @click="destroyed"
                        >关闭摄像头</el-button
                      >
                      <el-button type="primary" @click="init"
                        >开始识别</el-button
                      >
                    </el-row>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="login-form-btn"
              :loading="loading"
              @click="handleLogin"
              v-if="currentIndex !== 3"
            >
              登录
            </div>
            <div
              class="login-form-face-btn"
              v-if="currentIndex === 3 && !isShowV"
              @click="faceLoginBtn"
            >
              开启人脸识别
            </div>
          </div>
        </div>
      </div>
    </el-form>
    <div class="right" v-if="renlianShow">
      <div style="width: 100%; height: 100%" v-show="!isShowV">
        <img src="@/assets/login/kuang.png" class="img1" />
        <img src="@/assets/login/renlian.png" class="img2" />
        <button @click="faceLoginBtn" class="renlian_btn">人脸识别登录</button>
      </div>
      <div class="big-box" v-show="isShowV">
        <div class="video-box">
          <video
            id="video"
            width="320"
            height="240"
            preload
            autoplay
            loop
            muted
          ></video>
          <canvas id="canvas" width="320" height="240"></canvas>
        </div>
        <div class="success-face">
          <canvas
            id="screenshotCanvas"
            width="320"
            height="240"
            style="display: none"
          ></canvas>
          <div class="switch-button">
            <el-row>
              <el-button type="primary" @click="destroyed"
                >关闭摄像头</el-button
              >
              <el-button type="primary" @click="init">开始识别</el-button>
            </el-row>
          </div>
        </div>
      </div>
    </div>
    <ImageCheck v-if="isOpen" @back="handleBack" />
  </div>
</template>

<script>
import ImageCheck from "./components/Image-check.vue";
import tracking from "@/assets/js/tracking-min";
import "@/assets/js/face-min.js";
import md5 from "md5";
import { scanFaceLogin } from "@/api/home.js";
import {
  smsCheckCode,
  faceLogin,
  imageCheckCode,
  userLogin,
} from "@/api/system";
export default {
  name: "Login2",
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("请输入用户名"));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 2) {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        source: "PC",
        type: "1",
        account: "",
        pwd: "",
        sms_code: "",
        img_code: "",
      },
      loginRules: {
        account: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        pwd: [{ required: true, trigger: "blur", validator: validatePassword }],
        sms_code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
        ],
        img_code: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
      },
      passwordType: "password",
      imgUrl: "",
      currentIndex: 1,
      isOpen: false, // 是否图片验证
      count_down: 60, // 倒计时60s
      isCountDown: false, // 是否显示倒计时
      loading: false,
      //人脸识别用到的变量
      trackerTask: null,
      mediaStreamTrack: null,
      video: null,
      screenshotCanvas: null,
      uploadLock: true, // 上传锁
      isShowV: false,
      renlianShow: false,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      localStorage.setItem("admin_id", this.$route.params.id);
    }
  },
  components: {
    ImageCheck,
  },
  created() {
    this.getImgCode();
  },
  methods: {
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          if (this.currentIndex === 1) {
            if (this.imgCode !== this.loginForm.img_code) {
              this.$message.error("请输入正确的验证码");
              return;
            }
            let params = {
              func: "web.user.login",
              source: "PC",
              type: "1",
              account: this.loginForm.account,
              pwd: md5("pwd" + this.loginForm.pwd + "pwd"),
              img_code: this.loginForm.img_code,
            };
            userLogin(params)
              .then((res) => {
                if (res.data.data.flag === 1) {
                  this.$message.success("登录成功");
                  localStorage.setItem("user_id", res.data.data.data.user_id);
                  this.$router.push("/home/1");
                } else {
                  this.$message.error(res.data.data.errmsg);
                  return;
                }
              })
              .catch(() => {});
          } else {
            let params = {
              func: "web.user.login",
              source: "PC",
              type: "2",
              account: this.loginForm.account,
              sms_code: this.loginForm.sms_code,
            };
            userLogin(params)
              .then((res) => {
                if (res.data.data.flag === 1) {
                  this.$message.success("登录成功");
                  localStorage.setItem("user_id", res.data.data.data.user_id);
                  this.$router.push("/home/1");
                } else {
                  this.$message.error(res.data.data.errmsg);
                  return;
                }
              })
              .catch(() => {});
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 发送验证码
    handleSendCode() {
      if (this.loginForm.account === "") {
        this.$message.error("请输入手机号");
        return;
      }
      this.isOpen = true;
      // this.getAuthCode()
    },
    // 获取短信验证码
    async getAuthCode() {
      let params = {
        func: "sys.smsCheckCode.query",
        mobile: this.loginForm.account,
      };
      smsCheckCode(params)
        .then((res) => {
          this.isCountDown = true;
          // this.loginForm.sms_code = res.data.data.code
          this.getCountDown();
        })
        .catch((err) => {
          this.$message.error(res.data.errmsg);
        });
    },
    // 获取图片验证码
    async getImgCode() {
      const params = {
        func: "web.imageCheckCode.query",
      };
      imageCheckCode(params)
        .then((res) => {
          const result = res.data.data.data;
          this.imgUrl = result.image;
          this.imgCode = result.code;
        })
        .catch((err) => {
          this.$message.error(res.data.errmsg);
        });
    },
    // tab切换
    handleTabChange(e) {
      this.currentIndex = e;
      if (e === 2) {
        this.loginForm.sms_code = "";
      }
      if (e !== 3) {
        this.isShowV = false
      }
    },
    // 图片验证完成后事件
    handleBack(e) {
      this.isOpen = false;
      if (e) {
        this.getAuthCode();
      }
    },
    // 倒计时
    getCountDown() {
      let timer = null;
      let countDown = 60;
      timer = setInterval(() => {
        countDown--;
        this.count_down = countDown;
        if (countDown <= 0) {
          clearInterval(timer);
          this.isCountDown = false;
        }
      }, 1000);
    },
    // 删除用户名中的空格
    deleteSpace(e) {
      const username = e.target.value.replace(/\s+/g, "");
      this.form.setFieldsValue({
        loginName: username,
      });
    },
    // 校验验证码
    validateVerification(rule, value, callback) {
      const verification = this.show_num.join("").toLowerCase();
      if (value && value.toLowerCase() !== verification) {
        callback("验证码错误");
      }
      callback();
    },
    //人脸识别登录
    faceLoginBtn() {
      this.isShowV = this;
      this.init();
    },
    // 初始化设置
    init() {
      this.video = this.mediaStreamTrack = document.getElementById("video");
      this.screenshotCanvas = document.getElementById("screenshotCanvas");

      let canvas = document.getElementById("canvas");
      let context = canvas.getContext("2d");

      // 固定写法
      let tracker = new window.tracking.ObjectTracker("face");
      tracker.setInitialScale(4);
      tracker.setStepSize(2);
      tracker.setEdgesDensity(0.1);
      //摄像头初始化
      this.trackerTask = window.tracking.track("#video", tracker, {
        camera: true,
      });

      let _this = this;

      //监听图像变化
      tracker.on("track", function (event) {
        // 检测出人脸 绘画人脸位置
        context.clearRect(0, 0, canvas.width, canvas.height);
        event.data.forEach(function (rect) {
          context.strokeStyle = "#76e535";
          context.strokeRect(rect.x, rect.y, rect.width, rect.height);
        });
        // event.data.length长度为多少代表检测几张人脸
        if (_this.uploadLock && event.data.length) {
          //上传图片
          _this.screenshotAndUpload();
        }
      });
    },
    // 上传图片-扫脸登录
    screenshotAndUpload() {
      // 上锁避免重复发送请求
      this.uploadLock = false;

      // 绘制当前帧图片转换为base64格式
      let canvas = this.screenshotCanvas;
      let video = this.video;
      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      //base64Img为最终人像数据
      let base64Img = canvas.toDataURL("image/jpeg");
      // 扫脸登录
      scanFaceLogin({
        func: "web.dh.shop.scan.face.login",
        face_image: base64Img,
      }).then((res) => {
        if (res.data.data.flag === 1) {
          // 请求接口成功以后打开锁
          this.uploadLock = true;
          this.$message.success(res.data.data.errmsg);
          localStorage.setItem("user_id", res.data.data.data);
          this.$router.push("/home/1");
          this.destroyed();
        } else {
          this.$message.error(res.data.data.errmsg);
          this.destroyed();
          setTimeout(() => {
            this.$router.go(0);
          }, 500);
        }
      });
    },

    //关闭摄像头
    destroyed() {
      if (!this.mediaStreamTrack) {
        return;
      }
      this.mediaStreamTrack.srcObject.getTracks()[0].stop();
      this.trackerTask.stop();
      this.isShowV = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.main {
  width: 100%;
  height: 100%;
  background: url("@/assets/login/logo-BG.png") 100% 100% no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 15%;
  box-sizing: border-box;

  .left {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin-top: 15%;

    img {
      width: 40%;
      height: 15%;
    }

    span {
      color: #ffffffff;
      width: 70%;
      line-height: 20px;
      margin-top: 10%;
    }
  }

  .right {
    width: 50%;
    margin-top: 8%;
    position: relative;

    .img1 {
      width: 70%;
      height: 55%;
    }

    .img2 {
      width: 70%;
      height: 55%;
      position: absolute;
      top: 0;
      left: 0;
    }

    .renlian_btn {
      background-color: #ee7f40ff;
      width: 60%;
      height: 6%;
      border-radius: 30px;
      margin: 6%;
    }
  }

  .big-box {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 320px;
    //border: 1px solid #ddd;
    //height: 700px;
    transform: translate(-50%);

    .video-box {
      width: 320px;
      height: 240px;

      #canvas {
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .success-face {
      margin-top: 40px;
      width: 320px;
      height: 240px;
      //border: #ddd solid 1px;
    }
  }
}
</style>
<style lang="scss">
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: #000000;
  }
}

.login-container {
  position: relative;

  .el-input {
    display: inline-block;
    height: 52px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      height: 52px;
    }
  }

  .el-form-item {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    color: #000000;
  }
}

.login-form-item-input {
  position: relative;
  width: 458px;
  height: 52px;
  line-height: 52px;
  border-radius: 8px;
  margin-bottom: 12px;
}

.code-img {
  position: absolute;
  right: 0;
  top: 6px;
  width: 80px;
  height: 40px;
}

.login-form-btn {
  position: absolute;
  bottom: 57px;
  left: 51px;
  width: 458px;
  height: 52px;
  line-height: 52px;
  font-size: 18px;
  text-align: center;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  background: linear-gradient(270deg, #31afff 0%, #1677fe 100%);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.login-form-face {
  width: 216px;
  height: 216px;
  margin-left: 121px;
}

.login-form-face-btn {
  position: absolute;
  bottom: 57px;
  left: 51px;
  width: 458px;
  height: 52px;
  line-height: 52px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(270deg, #31afff 0%, #1677fe 100%);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.img-code {
  font-size: 25px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
}
</style>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  // position: relative;
  // width: 100%;
  // height: 100%;
  // background-size: 100%;
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  // overflow: hidden;

  .login-form {
    position: absolute;
    left: 58.5%;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    width: 560px;
    max-width: 100%;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    margin-bottom: 27px;

    .img-text {
      width: 503px;
      height: 46px;
      vertical-align: middle;
    }

    // .title {
    //   font-size: 26px;
    //   color: $light_gray;
    //   margin: 10px auto 30px auto;
    //   text-align: center;
    //   font-weight: bold;
    // }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  // #btn-sync {
  //   border: none;
  //   background-color: transparent;
  //   color: red;
  // }
  // #btn-sync:hover {
  //   background-color: transparent;
  // }
  .item-canvas {
    canvas {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
  }
}

.login-box-wrap {
  width: 560px;
  height: 620px;
  background: rgba(255, 255, 255, 0.24);
  border-radius: 20px;
  padding-top: 22px;
  padding-left: 11px;
}

.login-box-bg {
  width: 538px;
  height: 584px;
  background: #f2f9ff;
  border-radius: 20px;

  .login-logo {
    width: 436px;
    height: 64px;
    margin-left: 51px;
    margin-top: 34px;
    margin-bottom: 31px;
  }
}

.login-box-main {
  width: 538px;
  height: 455px;
  background: #ffffff;
  border-radius: 20px;

  .login-navs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 60px 0;
  }
}

.line {
  width: 1px;
  height: 25px;
  background: #f5f5f5;
}

.login-navs-item {
  cursor: pointer;
  color: #333333;
  font-size: 22px;
}

.login-navs .on {
  color: #4397c7;
  font-weight: 600;
}

.login-form-item {
  padding-left: 40px;
  padding-right: 40px;
  margin-top: 36px;
}

.code-box {
  ::v-deep .el-input {
    width: 75%;
  }

  ::v-deep .el-form-item__content {
    display: flex;
    justify-content: space-between;
  }

  .code-text {
    cursor: pointer;
    color: #4397c7;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .code-down {
    display: inline-block;
    width: 75px;
    text-align: right;
    color: #ccc;
    font-size: 14px;
  }
}
.renlianimg {
  margin: 80px auto;
  display: block;
}
</style>